import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { getWidget } from '../../../shared/widgets';
import Form from '../../../components/Form/Form';
import { useSnackbar } from 'notistack';
import { CREATE_USER_QUERY, GET_USERS_QUERY, UPDATE_USER_STATUS, UPDATE_USER_QUERY, GET_INDIVIDUAL_PROFILE, GET_USER_ROUNDS, GET_USERS_ROUNDS, UPDATE_USER_ISVISITED } from '../query';
import DownloadIcon from '@mui/icons-material/Download';
import { Checkbox } from '@mui/material'
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import UserProfile from './user_profile';
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import SendEmail from '../../../shared/hoc/modal/sendEmail';
import Select from '../../../components/Form/Select/Select';
import FilterUser from '../filter-user/FilterUser';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';

const INITIAL_DATA = {
    name: '',
    email: '',
    phone: '',
    status: '',
    isVisited: ''
};

export default function Users({ status }: any) {

    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const [formValues, setFormValues] = useState(INITIAL_DATA);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [profileModalOpen, setProfileModalOpen] = React.useState(false);
    const [editMode, setEditMode]: any = React.useState(false);
    const [sendEmailConfirmModal, setSendEmailConfirmModal] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [getUserProfile, setGetUserProfile]: any[] = React.useState([]);
    const [userImage, setUserImage]: any = React.useState('');
    const [isVisited, setIsVisited] = useState(false);
    const [filterData, setFilterData]: any = React.useState({});
    const [createUser] = useMutation(CREATE_USER_QUERY);
    const [updateUser] = useMutation(UPDATE_USER_QUERY);
    const [getIndividualProfile] = useMutation(GET_INDIVIDUAL_PROFILE);
    const [updateUserVisits] = useMutation(UPDATE_USER_ISVISITED);
    const [updateUserStatus] = useMutation(UPDATE_USER_STATUS);
    const { refetch: updateUserRound } = useQuery(GET_USERS_ROUNDS, { skip: true });

    type ChildRef = {
        refetchData: (data: any) => void;
    };

    const handleRefetch = () => {
        childRef.current?.refetchData({
            status: filterData?.status,
            search: searchText
        });
    };


    // Stop Loading
    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])


    // Page Meta
    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Users");
        }
    }, [pageMeta]);





    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };


    const onSearchTextChange = (event: any) => {
        setSearchText(event.target.value)
    }



    const FormFields = {
        form_name: "User",
        inputControls: [
            { name: "name", label: "Name", value: formValues.name, multiline: false },
            { name: "email", label: "Email", value: formValues.email, multiline: false },
            { name: "phone", label: "Phone Number", value: formValues.phone, multiline: false }
        ]
    }


    const ActionBarConfig: WidgetProps[] = [

        {
            type: "filter-menu",
            children: <FilterUser filterData={filterData} onFilterChange={handleFilterChange} />,
            sx: {
                order: 1
            }
        },
        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 1
                }
            }
        },
        {
            type: "button",
            label: responsiveString({ xs: "Add", sm: "Add User" }),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true);
                setEditMode(false);
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 3
                }
            }
        }
    ]


    const TableConfig = useMemo(() => {
        return [

            {
                label: "Sl.No",
                align: "left",
                accessor: (row: any) => row.slno
            },
            {
                label: "Name",
                align: "left",
                accessor: (row: any) => row.name
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.email
            },
            {
                label: "Phone",
                align: "left",
                accessor: (row: any) => row.phone
            },
            {
                label: "Password",
                align: "left",
                accessor: (row: any) => row.decrytPassword
            },
            {
                label: "Status",
                align: "left",
                accessor: (row: any) => {
                    return <>
                        <Select options={userStatus} handleDropDown={(event: React.ChangeEvent<HTMLInputElement>) => handleUserStatus(event, row)} value={row.status} />
                    </>
                }
            },
            {
                label: "Round Status",
                align: "left",
                accessor: (row: any) => {
                    return <>
                        <Select options={roundStatus} handleDropDown={(event: React.ChangeEvent<HTMLInputElement>) => handleUserRoundStatus(event, row)} value={row.roundStatus} />
                    </>
                }
            },
            {
                label: "Visited",
                align: "left",
                accessor: (row: any, index: number) => {

                    return <>
                        <Checkbox key={row.id ?? index} checked={row.isVisited} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUserVisit(event, row)} />
                    </>
                }
            },
            {
                label: "Action",
                align: "left",
                width: 130,
                accessor: (row: any) => {
                    return <>
                        <ActionMenu
                            button={{
                                type: "icon-button",
                                icon: <MoreVertOutlined />,
                                size: 'small',
                                color: 'primary',
                                sx: { color: 'white' }

                            }}
                            children={
                                [
                                    <MenuItem onClick={(event) => { handleGetProfile(row); handleProfileImage(row) }}>View Profile</MenuItem>,
                                    <MenuItem key={row.id} onClick={(event) => { setSelectedUser(row); setAddModalOpen(true); event.stopPropagation(); setEditMode(true); handleFormData(row); }}>Edit User</MenuItem>,
                                ]
                            }
                        />
                    </>
                }
            }
        ]
    }, [])


    const handleFormData = (data: any) => {
        setFormValues({ name: data.name, email: data.email, phone: data.phone, status: data.status, isVisited: data.status })
    }

    const userStatus = [{ name: 'Open' }, { name: 'Round 1' }, { name: 'Round 2' }, { name: 'Round 3' }]
    const roundStatus = [{ name: 'Open' }, { name: 'Failed' }]

    const handleUserStatus = async (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
        const variables = {
            updateUserDataId: parseInt(row.id),
            status: event.target.value,
            role: "admin"
        }

        const { data } = await updateUserStatus({ variables })

        const responseData = data.updateUserData

        if (responseData.errors?.length > 0) {
            console.error('Error:', responseData.errors);
            enqueueSnackbar(responseData.errors[0], { variant: "error" });
        } else {
            enqueueSnackbar('User status updated', { variant: "success" });
            handleRefetch()
            handleReset();
        }
    }

    const handleUserRoundStatus = async (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
        const variables = {
            updateUserDataId: parseInt(row.id),
            roundStatus: event.target.value,
            role: "admin"
        }

        const { data } = await updateUserStatus({ variables })

        const responseData = data.updateUserData

        if (responseData.errors?.length > 0) {
            console.error('Error:', responseData.errors);
            enqueueSnackbar(responseData.errors[0], { variant: "error" });
        } else {
            enqueueSnackbar('User status updated', { variant: "success" });
            handleRefetch()
            handleReset();
        }
    }





    const params = {
        status: filterData?.status,
        search: searchText
    }

    const pagination = {
        enable: true,
        page: page,
        limit: 100,
        onChange: (page: any) => {
            setPage(page)
        }
    }

    const handleUserVisit = async (event: React.ChangeEvent<HTMLInputElement>, row: any) => {

        const variables = {
            updateUserVisitsId: parseInt(row.id),
            isVisited: event.target.checked,
        }

        const { data } = await updateUserVisits({ variables })

        const responseData = data.updateUserVisits

        if (responseData.errors?.length > 0) {
            console.error('Error:', responseData.errors);
            enqueueSnackbar(responseData.errors[0], { variant: "error" });
        } else {
            enqueueSnackbar('User status updated', { variant: "success" });
            handleRefetch()
            handleReset();
        }

    };



    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues({
            ...formValues,
            [name]: value
        });

    }

    console.log(editMode)

    const handleSubmit = async () => {

        const variables = {
            name: formValues.name,
            email: formValues.email,
            phone: formValues.phone,
            status: formValues.status,
            ...(editMode && { updateUserDataId: parseInt(selectedUser.id) })
        };


        console.log(variables)

        try {

            console.log(editMode)
            const { data } = editMode ? await updateUser({ variables }) : await createUser({ variables });

            const responseData = editMode ? data.updateUserData : data.register;
            if (responseData.errors?.length > 0) {
                console.error('Error:', responseData.errors);
                enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(responseData.message[0], { variant: "success" });
                handleRefetch()
                handleReset();
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }
    };


    const handleReset = () => {
        setFormValues(INITIAL_DATA)
        setAddModalOpen(false)
        setUserImage('')
    }


    const handleGetProfile = async (user: any) => {

        const { data } = await getIndividualProfile({
            variables: { getIndividualProfilesId: parseInt(user.id) }
        });

        if (data.getIndividualProfiles.errors?.length > 0) {
            enqueueSnackbar(data.getIndividualProfiles.errors[0], { variant: "error" });

        } else {

            if (data.getIndividualProfiles.profile === null) {
                enqueueSnackbar("Profile Not Updated", { variant: "error" });
                setProfileModalOpen(false)
            }
            else {
                setProfileModalOpen(true)
                setGetUserProfile(data.getIndividualProfiles.profile[0])
            }
        }
    }


    // const handleProfileImage = async (user: any) => {

    //     const fileName = `${user.id}_${user.name}`
        

    //     const response = await fetch(`https://ekl.api.aiotrix.com/get-profile-image/${fileName}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //     })
    //     .then(response => response.json())
    //     .then(json => {

    //         const binaryData = btoa(String.fromCharCode(...new Uint8Array(json.profile_image.data)))
    //         let imageUrl = `data:image/*;base64,${binaryData}`

    //         setUserImage(imageUrl)
    //     })
    // }


    const handleProfileImage = async (user:any) => {
        const fileName = `${user.id}_${user.name}`;

        console.log(fileName)
        
        try {
            const response = await fetch(`https://ekl.api.aiotrix.com/get-profile-image/${fileName}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'image/jpeg' // Adjust this if you expect different image types
                },
            });
    
            if (response.ok) {
                const imageBlob = await response.blob();
                const imageUrl = URL.createObjectURL(imageBlob);
                setUserImage(imageUrl);
            } else {
                console.error('Failed to fetch image:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };
    


    const handleUserRounds = async (round_name: any) => {

        const { data } = await updateUserRound({
            variables: { roundName: round_name }
        });

        if (data.getUserRounds.errors?.length > 0) {
            enqueueSnackbar(data.getUserRounds.errors[0], { variant: "error" });

        } else {

            if (data.getUserRounds.user === null) {
                enqueueSnackbar("User Not Found", { variant: "error" });
            }
            else {
                enqueueSnackbar("Selected for round 2", { variant: "error" });
            }
        }
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)



    useEffect(() => {
        setGetUserProfile(getUserProfile)
    }, [getUserProfile])

    return (
        <>
            <div>
                <TablePageHoc
                    actions={ActionBarConfig}
                    columns={TableConfig as TableColumn[]}
                    query={GET_USERS_QUERY}
                    params={params}
                    pagination={pagination}
                    // limit={100}
                    ref={childRef}
                    sourceAccessor={(data) => data.getAllUsers.user}
                    countAccessor={(data) => data.getAllUsers.user !== null ? data.getAllUsers.user.length : 0} />

                {
                    getWidget(
                        {
                            type: "modal",
                            title: editMode ? "Edit User" : "Add User",
                            modalOpen: addModalOpen,
                            onClose: () => setAddModalOpen(false),
                            size: 'sm',
                            children: <Form formFields={FormFields} handleClose={handleReset} handleChange={handleInputChange} handleSubmit={handleSubmit} />,
                        }
                    )
                }

                {
                    getWidget(
                        {
                            type: "modal",
                            title: "Profile",
                            modalOpen: profileModalOpen,
                            onClose: () => { setProfileModalOpen(false); setUserImage('') },
                            children: <UserProfile profile={getUserProfile} image={userImage} />,
                            size: 'md'
                        })
                }

                {/* <SendEmail modalOpen={isVisited} onClose={() => setIsVisited(false)} onConfirm={handleUserVisit} title={'Are you sure?'} children={'Do you want to send email for all the visited members?'} /> */}

            </div>

        </>
    )
}
