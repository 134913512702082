import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../../store/reducers/AppSlice';
import TablePageHoc from '../../../../shared/hoc/table-list/table-list-hoc';
import { responsiveString } from '../../../../shared/utils/util-functions';
import { TableColumn, WidgetProps } from '../../../../shared/types/widget-types';
import ActionMenu from '../../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Checkbox, Slider } from '@mui/material';
import Select from '../../../../components/Form/Select/Select';
import { enqueueSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import EllipsisBox from '../../../../shared/widgets/EllipsisBox/EllipsisBox';



const GET_USERS_ANS_QUERY = `query GetUserAnswers($userId: Int) {
  getUserAnswers(userId: $userId) {
    message
    errors
    success
    questionAnswers {
      id
      score
      answer
      Question {
        question
        id
        description
      }
      User {
        id
      }
    }
  }
}
`


const UPDATE_USER_SCORE = gql`mutation UpdateUserScore($userId: Int, $questionId: Int, $score: Int) {
  updateUserScore(userId: $userId, questionId: $questionId, score: $score) {
    errors
    message
    success
  }
}`


export default function UsersAnsList() {

  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const childRef = useRef<ChildRef | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [updateUserScore] = useMutation(UPDATE_USER_SCORE);


  type ChildRef = {
    refetchData: (data: any) => void;
  };

  const userIdString = searchParams.get('userId') ?? '0';


  const handleRefetch = () => {
    childRef.current?.refetchData({
      userId: parseInt(userIdString, 10),
    });
  };


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stopPageLoading())
  }, [])

 

  const params = {
    userId: parseInt(userIdString, 10),
  };

  const pagination = {
    enable: true,
    page: page,
    limit: 100,
    onChange: (page: any) => {
      setPage(page)
    }
  }



  const userStatus = [{ name: 'Round 2' }, { name: 'Round 3' }]

  const handleSliderChange = (userId: any, questionId: any) => async (event: any, newValue: any) => {

    try {
      const { data } = await updateUserScore({
        variables: {
          userId: parseInt(userId),
          questionId: parseInt(questionId),
          score: newValue
        }
      });

      if (data.updateUserScore.success) {
        enqueueSnackbar(("Score Updated successfully"), { variant: "success" });
        handleRefetch(); // Optionally refetch data if necessary
      } else {
        console.error('Error updating score:', data.updateUserScore.errors);
        enqueueSnackbar(("Error updating score"), { variant: "error" });
      }
    } catch (error) {
      console.error('Error updating score', error);
    }
  };



  const ActionBarConfig: WidgetProps[] = [
 
  ]


  const TableConfig = useMemo(() => {
    return [

      {
        label: "Question",
        align: "left",
        width: 200,
        // accessor: (row: any) => row?.Question.question
        accessor: (row: any) => <EllipsisBox content={row.Question.question} minWidth={380} lineClamp={6} />
      },
      {
        label: "Answer",
        align: "left",
        // accessor: (row: any) => row?.answer
        accessor: (row: any) => <EllipsisBox content={row?.answer} minWidth={250} lineClamp={6} />
      },
      {
        label: "Score",
        align: "left",
        accessor: (row: any) => row?.score
      },
      {
        label: "Evaluate",
        align: "left",
        accessor: (row: any) => <Slider
          aria-label="Likith"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={10}
          value={row.score}
          onChange={handleSliderChange(row.User.id, row.Question.id)}
        />
      },


    ]
  }, [])


  return (
    <>

      <TablePageHoc
        actions={ActionBarConfig}
        columns={TableConfig as TableColumn[]}
        query={GET_USERS_ANS_QUERY}
        params={params}
        pagination={pagination}
        sourceAccessor={(data) => data.getUserAnswers.questionAnswers}
        countAccessor={(data) => data.getUserAnswers.questionAnswers.length}
        ref={childRef}
      />

    </>
  )
}
