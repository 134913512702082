import React, { useEffect, useState } from 'react'
import './user_profile.scss'

export default function UserProfile({ profile, image }: any) {

  return (
    <>
      <div className="profile">
        <div className="profile-header">
          {/* {profile.profile_image === null ? null : <img src={`data:image/png;base64,${profile.profile_image}`} alt="" />} */}
          <div className='image_profile'>
            {image ? <img src={image} alt="" /> : <></>}
          </div>


          <h4>{profile.User.name}</h4>
        </div>
        <div className="profile-body details">
          <div className="sections">
            <h4>College Details</h4>
            <div className='sub-section'>
              <p className='sub-heading'>College Name:</p>
              <p className='sub-text'>{profile.collegeName}</p>
            </div>
            <div className='sub-section'>
              <p className='sub-heading'>Address: </p>
              <p className='sub-text'>{profile.collegeAddress}</p>
            </div>
          </div>
          <div className="sections">
            <h4>Skills & Job Role</h4>
            <div className='sub-section'>
              <p className='sub-heading'>Skills: </p>
              <p className='sub-text'>{profile.skills?.join(', ')}</p>
            </div>
            <div className='sub-section'>
              <p className='sub-heading'>Job Role: </p>
              <p className='sub-text'>{profile.jobRole?.join(', ')}</p>
            </div>
          </div>

        </div>

        <div className="profile-body details">

          <h4 className='mt-4'>Projects</h4>
          {
            profile.projects?.map((items: any) => (

              <div className="sections">
                <h4>{items.name}</h4>
                <div className='sub-section'>
                  <p className='sub-heading'>Description: </p>
                  <p className='sub-text'>{items.description}</p>
                </div>
                <div className='sub-section'>
                  <p className='sub-heading'>Technologies: </p>
                  <p className='sub-text'>{items.technologies?.join(', ')}</p>
                </div>
              </div>

            ))
          }
        </div>

      </div>
    </>
  )
}
