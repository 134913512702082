import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';

function GuestPage() {
    const [path, setPath] = useState("")
    const isLogged = useSelector((state: any) => state.auth.isLoggedIn)
    const role = useSelector((state: any) => state.auth.role)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    useEffect(() => {
        dispatch(stopPageLoading())
        if (role === 'admin') {
            navigate("/admin/users")
        } else if(role == 'user') {
            console.log("navigating from here");
            
            navigate("/admin/users")
            // navigate("/ekalavya/dashboard")
        }
        console.log("coming here", role, isLogged);
        
        let redirectPath = localStorage.getItem('redirectPath');
        if (redirectPath) {
            navigate(redirectPath)
        }
    }, [isLogged, role])


    return (
        <>
            {
                isLogged ? (
                    <Navigate to={path} />
                ) : (
                    <Outlet />
                )

            }
        </>
    )
}

export default GuestPage