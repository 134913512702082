import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../../store/reducers/AppSlice';
import TablePageHoc from '../../../../shared/hoc/table-list/table-list-hoc';
import { responsiveString } from '../../../../shared/utils/util-functions';
import { TableColumn, WidgetProps } from '../../../../shared/types/widget-types';
import ActionMenu from '../../../../shared/widgets/action-menu/action-menu';
import { MoreVertOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import Select from '../../../../components/Form/Select/Select';
import { enqueueSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const GET_USERS_QUERY = `query GetAllUsers($status: String, $search: String, $getAllUsersId: Int) {
  getAllUsers(status: $status, search: $search, id: $getAllUsersId) {
    errors
    message
    success
    user {
      status
      role
      phone
      password
      name
      isVisited
      id
      email
      slno
      totalScore
    }
  }
}
`


const UPDATE_USER_STATUS = gql`mutation UpdateUserData($updateUserDataId: Int, $status: String) {
  updateUserData(id: $updateUserDataId, status: $status) {
    errors
    message
    success
  }
}`


export default function Round3UsersList() {

  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS);
  const childRef = useRef<ChildRef | null>(null);

  type ChildRef = {
    refetchData: (data: any) => void;
  };


  const handleRefetch = () => {
    childRef.current?.refetchData({
      status: "Round 2",
      search: searchText
    });
  };


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stopPageLoading())
  }, [])



  const params = {
    status: "Round 3",
    search: searchText
  }

  const pagination = {
    enable: true,
    page: page,
    limit: 100,
    onChange: (page: any) => {
      setPage(page)
    }
  }



  const userStatus = [{ name: 'Round 2' }, { name: 'Round 3' }]

  const handleUserStatus = async (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    event.stopPropagation();
    const variables = {
      updateUserDataId: parseInt(row.id),
      status: event.target.value,
      role: "admin"
    }

    const { data } = await updateUserStatus({ variables })

    const responseData = data.updateUserData

    if (responseData.errors?.length > 0) {
      console.error('Error:', responseData.errors);
      enqueueSnackbar(responseData.errors[0], { variant: "error" });
    } else {
      enqueueSnackbar('User status updated', { variant: "success" });
      handleRefetch()

    }
  }



  const ActionBarConfig: WidgetProps[] = [
  ]


  const TableConfig = useMemo(() => {
    return [

  
      {
        label: "Name",
        align: "left",
        accessor: (row: any) => row.name
      },
      {
        label: "Email",
        align: "left",
        accessor: (row: any) => row.email
      },
      {
        label: "Phone",
        align: "left",
        accessor: (row: any) => row.phone
      },
      {
        label: "Overall Score",
        align: "left",
        accessor: (row: any) => row.totalScore ? row.totalScore : 0
      },
      {
        label: "Status",
        align: "left",
        accessor: (row: any) => {
          return <>
            <Select options={userStatus}  handleDropDown={(event: React.ChangeEvent<HTMLInputElement>) => handleUserStatus(event, row)} value={row.status} />
          </>
        }
      }
    ]
  }, [])


  return (
    <>

      <TablePageHoc
        actions={ActionBarConfig}
        columns={TableConfig as TableColumn[]}
        query={GET_USERS_QUERY}
        params={params}
        pagination={pagination}
        sourceAccessor={(data) => data.getAllUsers.user}
        countAccessor={(data) => data.getAllUsers.user !== null ? data.getAllUsers.user.length : 0}
        ref={childRef}
      />

    </>
  )
}
