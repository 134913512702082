import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { getWidget } from '../../../shared/widgets';
import Form from '../../../components/Form/Form';
import { useSnackbar } from 'notistack';
import { CREATE_ROUND_QUERY, GET_ROUND_QUERY, UPDATE_ROUND_QUERY, UPDATE_ROUND_STATUS_QUERY } from '../query';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import Select from '../../../components/Form/Select/Select';


const INITIAL_DATA = {
    round_name: '',
    sub_title: '',
    instructions: '',
    rules: '',
    status: ''
};

export default function Rounds({ status }: any) {

    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const childRef = useRef<ChildRef | null>(null);
    const [formValues, setFormValues] = useState(INITIAL_DATA);
    const [createRounds] = useMutation(CREATE_ROUND_QUERY);
    const [updateRounds] = useMutation(UPDATE_ROUND_QUERY);
    const [updateRoundStatus] = useMutation(UPDATE_ROUND_STATUS_QUERY);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [editMode, setEditMode]: any = React.useState(false);
    const [selectedRounds, setSelectedRounds]: any[] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();

    type ChildRef = {
        refetchData: (data: any) => void;
    };

    const handleRefetch = () => {
        childRef.current?.refetchData({
          search: searchText ?? "",
          status: status ? status : null
        });
      };

    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Rounds");
        }
    }, [pageMeta]);


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }


    const FormFields = {
        form_name: "Round",
        inputControls: [
            {
                name: "round_name",
                label: "Round Name",
                value: formValues.round_name,
                multiline: false
            },
            {
                name: "sub_title",
                label: "Sub Title",
                value: formValues.sub_title,
                multiline: false
            },
            {
                name: "instructions",
                label: "Instructions",
                value: formValues.instructions,
                multiline: false
            },
            {
                name: "rules",
                label: "Rules",
                value: formValues.rules,
                multiline: false
            }
        ]
    }



    const ActionBarConfig: WidgetProps[] = [

        {
            type: "button",
            label: responsiveString({ xs: "Add", sm: "Add Rounds" }),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true);
                setEditMode(false);
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 3
                }
            }
        },
    ]


    const TableConfig = useMemo(() => {
        return [

            // {
            //     label: "Id",
            //     align: "left",
            //     accessor: (row: any) => row.id
            // },

            {
                label: "Round Name",
                align: "left",
                accessor: (row: any) => row.round_name
            },

            {
                label: "Sub Title",
                align: "left",
                accessor: (row: any) => row.sub_title
            },

            {
                label: "Instructions",
                align: "left",
                accessor: (row: any) => row.instructions
            },

            {
                label: "Rules",
                align: "left",
                accessor: (row: any) => row.rules
            },
            {
                label: "Status",
                align: "left",
                accessor: (row: any) => {
                    return <>
                        <Select options={roundStatus} handleDropDown={(event: React.ChangeEvent<HTMLInputElement>) => handleRoundStatus(event, row)} value={row.status} />
                    </>
                }
            },
            {
                label: "Action",
                align: "left",
                width: 130,
                accessor: (row: any) => {
                    return <>
                        <ActionMenu
                            button={{
                                type: "icon-button",
                                icon: <MoreVertOutlined />,
                                size: 'small',
                                color: 'primary',
                                sx: { color: 'white' }

                            }}
                            children={
                                [
                                    <MenuItem key={row.id} onClick={(event) => {
                                        setSelectedRounds(row);
                                        setAddModalOpen(true)
                                        event.stopPropagation();
                                        setEditMode(true)
                                        handleFormData(row)
                                    }}>Edit Rounds</MenuItem>,
                                ]
                            }
                        />
                    </>
                }
            }

        ]
    }, [])


    const params = {
        search: searchText,
        status: status ? status : null
    }

    const pagination = {
        enable: true,
        page: page,
        onChange: (page: any) => {
            setPage(page)
        }
    }

    const roundStatus = [{ name: 'Not Started' }, { name: 'Started' }, { name: 'Completed' }]

    const handleRoundStatus = async (event: React.ChangeEvent<HTMLInputElement>, row: any) => {

        const variables = {
            updateRoundsId: parseInt(row.id),
            status: event.target.value,
            role: "admin"
        }

        const { data } = await updateRoundStatus({variables})

        const responseData = data.updateRounds

        if (responseData.errors?.length > 0) {
            console.error('Error:', responseData.errors);
            enqueueSnackbar(responseData.errors[0], { variant: "error" });
        } else {
            enqueueSnackbar('Round status updated', { variant: "success" });
            handleRefetch()
            handleReset();
        }
    }


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues({
            ...formValues,
            [name]: value
        });

    }

    const handleSubmit = async () => {

        const variables = {
            roundName: formValues.round_name,
            subTitle: formValues.sub_title,
            instructions: formValues.instructions,
            rules: formValues.rules,
            role: 'admin',
            ...(editMode && {
                status: formValues.status,
                updateRoundsId: parseInt(selectedRounds.id)
            })
        }

        try {

            const { data } = editMode ? await updateRounds({ variables }) : await createRounds({ variables })

            const responseData = editMode ? data.updateRounds : data.createRounds;

            if (responseData.errors?.length > 0) {
                console.error('Error:', responseData.errors);
                enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(responseData.message[0], { variant: "success" });
                handleRefetch()
                handleReset();
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }

    }

    const handleFormData = (data: any) => {

        setFormValues({
            round_name: data.round_name,
            sub_title: data.sub_title,
            instructions: data.instructions,
            rules: data.rules,
            status: data.status
        })
    }


    const handleReset = () => {
        setFormValues(INITIAL_DATA)
        setAddModalOpen(false)
    }


    return (
        <>

            <TablePageHoc
                actions={ActionBarConfig}
                columns={TableConfig as TableColumn[]}
                query={GET_ROUND_QUERY}
                params={params}
                pagination={pagination}
                sourceAccessor={(data) => data.getAllRounds.rounds}
                countAccessor={(data) => data.getAllRounds.totalVisitors}
                ref={childRef}
            />

            {
                getWidget(
                    {
                        type: "modal",
                        title: editMode ? "Edit Rounds" : "Add Rounds",
                        modalOpen: addModalOpen,
                        onClose: () => setAddModalOpen(false),
                        size: 'md',
                        children: <Form formFields={FormFields} handleClose={handleReset} handleChange={handleInputChange} handleSubmit={handleSubmit} />,
                    }
                )
            }

        </>
    )
}