import React, { useMemo, useCallback, useLayoutEffect } from 'react';
import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';

const EllipsisBox = ({ content, minWidth, lineClamp }: any) => {
    const [isOverflow, setIsOverflow] = React.useState(false);
    const measureRef = React.useRef<HTMLSpanElement>(null);

    const checkOverflow = useCallback(() => {
        if (measureRef.current) {
            setIsOverflow(measureRef.current.scrollWidth > minWidth);
        }
    }, [minWidth]);

    useLayoutEffect(() => {
        checkOverflow();
    }, [content, minWidth, checkOverflow]);

    const styles = useMemo(() => {
        if (!isOverflow) return {};
        return {
            display: '-webkit-box',
            whiteSpace: 'normal',
            wordBreak: 'break-all',
            WebkitLineClamp: lineClamp,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: minWidth,
        };
    }, [isOverflow, minWidth, lineClamp]);

    const contentBox = (
        <Box ref={measureRef} sx={styles}>
            {content}
        </Box>
    );

    const tooltipContent = useMemo(() => content, [content]);

    return isOverflow ? (
        <Tooltip title={tooltipContent}>
            {contentBox}
        </Tooltip>
    ) : (
        contentBox
    );
};

export default EllipsisBox;
