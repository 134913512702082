import React, { useEffect, useState } from 'react'
import './select.scss'

export default function Select({ options, handleDropDown, value }: any) {

  return (
    <>
      <select name="status" id="status" className='custom-select' onClick={(event) => event.stopPropagation()} onChange={handleDropDown} value={value}>
        {
          options.map((items: any) => (
            <option value={items.name}>{items.name}</option>
          ))
        }

      </select>
    </>
  )
}
