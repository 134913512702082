import React, { useState, useEffect } from 'react'
import { FormGroup, TextField } from '@mui/material';
import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material'

const Form =({ formFields, handleClose, handleSubmit, handleChange }: any) => {

    useEffect(() => {

        console.log(formFields)

    }, [])

    return (
        <>
            <FormGroup>
                <Grid container justifyContent={'space-between'}>

                    {
                        formFields.inputControls?.map((input: any, index:any) => (
                            <Grid xs={12} key={index}>
                                <TextField
                                    id={input.name}
                                    label={input.label}
                                    autoComplete='off'
                                    variant="outlined"
                                    value={input.value}
                                    name={input.name}
                                    sx={{ mb: 20 }}
                                    fullWidth={true}
                                    onChange={handleChange}
                                    multiline={input.multiline}
                                />
                            </Grid>
                        ))
                    }
                </Grid>

                <Divider sx={{ mx: -24, mb: 20 }} />

                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" color="secondary" onClick={handleClose} >Cancel</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
                    </Grid>
                </Grid>

            </FormGroup>

        </>
    )
}

export default Form
