import React, { useEffect, useState } from 'react'
import LoginHoc from '../../../shared/hoc/login/Login.hoc'
import style from './Login.module.scss'
import logo from '../../../assets/images/aiotrix.png';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateAccessTokenAndLoginStatus } from '../../../store/reducers/AuthSlice'
import { useCookies } from 'react-cookie'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack';
import jwt_decode from "jwt-decode";
import { stopPageLoading } from '../../../store/reducers/AppSlice'

const LeftContent: React.FC = () => {
  return (
    <div className={style.left_content_wrapper}>

    </div>
  )
}

const SUBMIT_LOGIN_DETAILS = gql`mutation Mutation($email: String!, $password: String!) {
  Login(email: $email, password: $password) {
    success
    token {
      accessToken
      refreshToken
    }
    user {
      email
      password
      role
    }
    errors
  }
}
`;


export default function LoginPage() {

  const [submitLoginDetails, { data: LoginData, loading, error }] = useMutation(SUBMIT_LOGIN_DETAILS);

  const dispatch = useDispatch()

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();


  const [cookies, setCookie] = useCookies(['token', 'refreshToken'])
  const [userData, setUserData] = useCookies(['user', 'email'])

  const [hasErrors, setHasErrors] = useState(false)

  const [errors, setErrors] = useState([])

  dispatch(stopPageLoading())




  const signIn = async (data: any) => {

    let res = await submitLoginDetails({ variables: { email: data.email, password: data.password } })

    if (!res.data.Login.success) {
      enqueueSnackbar(res.data.Login.errors[0], { variant: "error" });
    }
    else {

      console.log(res.data)

      var decoded = jwt_decode(res.data.Login.token.accessToken);
      dispatch(updateAccessTokenAndLoginStatus({ token: res.data.Login.token.accessToken, isLoggedIn: true, decodedTokenData: decoded, role: res.data.Login.user.role }));
      setCookie('token', res.data.Login.token.accessToken, { path: '/' })
      setUserData('user', res.data.Login.user.email, { path: '/' })
      console.log(decoded);

      setCookie('refreshToken', res.data.Login.token.refreshToken, { path: '/' })
      let redirectPath = localStorage.getItem('redirectPath');
      if (res.data.Login.user.role === 'admin') {

        navigate('/admin/users');
      }
       else if (redirectPath) {
        navigate(redirectPath);
        // localStorage.removeItem('redirectPath');
      } else {
          navigate('/admin/users');
        
      }
    }
  }



  return (
    <div>

      <LoginHoc
        LeftContent={LeftContent}
        hasError={hasErrors}
        errors={errors}
        logo={<img src={logo} className={style.logo} alt='' />}
        onLogin={signIn}
      />

    </div>
  )

}
