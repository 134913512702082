import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { getWidget } from '../../../shared/widgets';
import Form from '../../../components/Form/Form';
import { useSnackbar } from 'notistack';
import { CREATE_QUESTIONS_QUERY, GET_QUESTIONS_QUERY, UPDATE_QUESTIONS_QUERY } from '../query';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';

const INITIAL_DATA = {
    question: '',
    description: '',
    timer: '',
};

export default function Questions({ status }: any) {

    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const childRef = useRef<ChildRef | null>(null);
    const [formValues, setFormValues] = useState(INITIAL_DATA);
    const [createQuestion] = useMutation(CREATE_QUESTIONS_QUERY);
    const [updateQuestion] = useMutation(UPDATE_QUESTIONS_QUERY);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [editMode, setEditMode]: any = React.useState(false);
    const [selectedQuestions, setSelectedQuestions]: any[] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();

    type ChildRef = {
        refetchData: (data: any) => void;
    };


    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText
        });
    };



    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])



    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Questions");
        }
    }, [pageMeta]);



    const onSearchTextChange = (event: any) => {
        setSearchText(event.target.value)
    }



    const FormFields = {
        form_name: "Question",
        inputControls: [
            {
                name: "question",
                label: "Question",
                value: formValues.question,
                multiline: false
            },
            {
                name: "description",
                label: "Description",
                value: formValues.description,
                multiline: false
            },
            {
                name: "timer",
                label: "Timer",
                value: formValues.timer,
                multiline: false
            }
        ]
    }



    const ActionBarConfig: WidgetProps[] = [

   

        {
            type: "button",
            label: responsiveString({ xs: "Add", sm: "Add Questions" }),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true);
                setEditMode(false);
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 3
                }
            }
        },

    ]



    const TableConfig = useMemo(() => {
        return [

            {
                label: "Sl.No",
                align: "left",
                accessor: (row: any) => row.id
            },
            {
                label: "Question",
                align: "left",
                accessor: (row: any) =><EllipsisBox content={row.question} minWidth={380} lineClamp={6} />
            },

            {
                label: "Description",
                align: "left",
                accessor: (row: any) => row.description
            },
            {
                label: "Timer",
                align: "left",
                accessor: (row: any) => row.timer
            },
            {
                label: "Action",
                align: "left",
                width: 130,
                accessor: (row: any) => {
                    return <>
                        <ActionMenu
                            button={{
                                type: "icon-button",
                                icon: <MoreVertOutlined />,
                                size: 'small',
                                color: 'primary',
                                sx: { color: 'white' }

                            }}
                            children={
                                [
                                    <MenuItem key={row.id} onClick={(event) => {
                                        setSelectedQuestions(row);
                                        setAddModalOpen(true)
                                        event.stopPropagation();
                                        setEditMode(true)
                                        handleFormData(row)
                                    }}>Edit Questions</MenuItem>,
                                ]
                            }
                        />
                    </>
                }
            }
        ]
    }, [])


    const params = {
        search: searchText,
    }

    const pagination = {
        enable: true,
        page: page,
        limit:100,
        onChange: (page: any) => {
            setPage(page)
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues({
            ...formValues,
            [name]: value
        });

    }

    const handleSubmit = async () => {

        const variables = {
            question: formValues.question,
            description: formValues.description,
            timer: formValues.timer,
            role: 'admin',
            ...(editMode && {
                updateQuestionsId: parseInt(selectedQuestions.id)
            })
        }

        try {

            const {data} = editMode ? await updateQuestion({ variables }) : await createQuestion({ variables })

            const responseData = editMode ? data.updateQuestions : data.createQuestions;

            if (responseData.errors?.length > 0) {
                console.error('Error:', responseData.errors);
                enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(responseData.message[0], { variant: "success" });
                handleRefetch();
                handleReset();
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }

    }

    const handleFormData = (data: any) => {

        setFormValues({
            question: data.question,
            description: data.description,
            timer: data.timer
        })
    }

    const handleReset = () => {
        setFormValues(INITIAL_DATA)
        setAddModalOpen(false)
    }


    return (
        <>
            <TablePageHoc
                actions={ActionBarConfig}
                columns={TableConfig as TableColumn[]}
                query={GET_QUESTIONS_QUERY}
                params={params}
                pagination={pagination}
                sourceAccessor={(data) => data.getAllQuestions.questions}
                countAccessor={(data) => data.getAllQuestions.questions}
                ref={childRef}
            />

            {
                getWidget(
                    {
                        type: "modal",
                        title: editMode ? "Edit Question" : "Add Question",
                        modalOpen: addModalOpen,
                        onClose: () => setAddModalOpen(false),
                        size: 'md',
                        children: <Form formFields={FormFields} handleClose={handleReset} handleChange={handleInputChange} handleSubmit={handleSubmit} />,
                    }
                )
            }
        </>
    )
}