import { gql } from '@apollo/client'


// Rounds

const CREATE_ROUND_QUERY = gql`mutation CreateRounds($roundName: String, $subTitle: String, $instructions: String, $rules: String, $role: String) {
    createRounds(round_name: $roundName, sub_title: $subTitle, instructions: $instructions, rules: $rules, role: $role) {
      success
      errors
      message
      rounds {
        round_name
        sub_title
        instructions
        rules
      }
    }
  }`

const GET_ROUND_QUERY = `query GetAllRounds($roundName: String, $subTitle: String, $instructions: String, $rules: String) {
    getAllRounds(round_name: $roundName, sub_title: $subTitle, instructions: $instructions, rules: $rules) {
      success
      rounds {
        id
        round_name
        sub_title
        instructions
        rules
        status
      }
      errors
      message
    }
  }
`

const UPDATE_ROUND_QUERY = gql`mutation Mutation($updateRoundsId: Int, $roundName: String, $subTitle: String, $instructions: String, $rules: String, $role: String) {
  updateRounds(id: $updateRoundsId, round_name: $roundName, sub_title: $subTitle, instructions: $instructions, rules: $rules, role: $role) {
    errors
    message
    success
  }
}`


const UPDATE_ROUND_STATUS_QUERY = gql`mutation UpdateRounds($updateRoundsId: Int, $status: String, $role: String) {
  updateRounds(id: $updateRoundsId, status: $status, role: $role) {
    errors
    message
    success
  }
}`




// Questions

const GET_QUESTIONS_QUERY = `query GetAllQuestions {
    getAllQuestions {
      success
      questions {
        id
        question
        description
        timer
      }
      errors
      message
    }
  }
`

const CREATE_QUESTIONS_QUERY = gql`mutation Mutation($question: String, $description: String, $role: String, $timer: String) {
  createQuestions(question: $question, description: $description, role: $role, timer: $timer) {
    success
    questions {
      question
      description
      timer
    }
    errors
    message
  }
}`


const UPDATE_QUESTIONS_QUERY = gql`mutation UpdateQuestions($updateQuestionsId: Int, $question: String, $description: String, $role: String, $timer: String) {
  updateQuestions(id: $updateQuestionsId, question: $question, description: $description, role: $role, timer: $timer) {
    errors
    message
    success
  }
}`



// Users

const GET_USERS_QUERY = `query GetAllUsers($status: String, $search: String, $getAllUsersId: Int) {
  getAllUsers(status: $status, search: $search, id: $getAllUsersId) {
    errors
    message
    success
    user {
      status
      roundStatus
      decrytPassword
      role
      phone
      password
      name
      isVisited
      id
      email
      slno
    }
  }
}
`

const CREATE_USER_QUERY = gql`mutation Register($name: String, $email: String, $phone: String) {
  register(name: $name, email: $email, phone: $phone) {
    success
    errors
    message
  }
}`


const UPDATE_USER_QUERY = gql`mutation UpdateUserData($updateUserDataId: Int, $name: String, $email: String, $phone: String) {
  updateUserData(id: $updateUserDataId, name: $name, email: $email, phone: $phone) {
    success
    errors
    message
  }
}`


const UPDATE_USER_ISVISITED = gql`mutation Mutation($updateUserVisitsId: Int, $isVisited: Boolean) {
  updateUserVisits(id: $updateUserVisitsId, isVisited: $isVisited) {
    message
    errors
    success
  }
}
`

const UPDATE_USER_STATUS = gql`mutation UpdateUserData($updateUserDataId: Int, $status: String, $roundStatus: String) {
  updateUserData(id: $updateUserDataId, status: $status, roundStatus:$roundStatus) {
    errors
    message
    success
  }
}`


const GET_INDIVIDUAL_PROFILE = gql`mutation Mutation($getIndividualProfilesId: Int) {
  getIndividualProfiles(id: $getIndividualProfilesId) {
    success
    errors
    message
    profile {
      id
      skills
      jobRole
      collegeName
      collegeAddress
      projects {
        name
        description
        technologies
      }
      userId
      profile_image
      User {
        id
        name
      }
    }
  }
}`


const GET_USER_ROUNDS = `query GetUserRounds($roundName: String) {
  getUserRounds(roundName: $roundName) {
    success
    user {
      id
      name
      email
      phone
      role
      status
      isVisited
    }
    errors
    message
  }
}`

const GET_USERS_ROUNDS = gql`query GetUserRounds($roundName: String) {
  getUserRounds(roundName: $roundName) {
    success
    user {
      id
      name
      email
      phone
      role
      status
      isVisited
    }
    errors
    message
  }
}`

const ROUND_COMMENTS = gql`mutation UpdateUserComments($userId: Int, $comments: String, $roundId: Int) {
  updateUserComments(userId: $userId, comments: $comments, roundId: $roundId) {
    success
    message
    errors
  }
}`



// Teams

const GET_TEAM_QUERY = `query GetAllTeams {
  getAllTeams {
    success
    teams {
      id
      team_name
      team_description
      topic
      memberCount
    }
    errors
    message
  }
}`

const CREATE_TEAM_QUERY = gql`mutation CreateTeams($teamName: String, $teamDescription: String, $topic: String, $role: String) {
  createTeams(team_name: $teamName, team_description: $teamDescription, topic: $topic, role: $role) {
    errors
    success
    message
  }
}`


const UPDATE_TEAM_QUERY = gql`mutation UpdateTeams($updateTeamsId: Int, $teamName: String, $teamDescription: String, $topic: String, $role: String) {
  updateTeams(id: $updateTeamsId, team_name: $teamName, team_description: $teamDescription, topic: $topic, role: $role) {
    errors
    message
    success
  }
}`


const GET_TEAM_MEMBERS = gql`query Query($teamId: Int) {
  getTeamMembers(teamId: $teamId) {
    data {
      User {
        id
        email
        name
        phone
        isVisited
      }
      Team {
        id
        team_name
        memberCount
        team_description
        topic
      }
      id
      Profile {
        id
        userId
        collegeAddress
        collegeName
      }
      isAccepted
    }
    errors
    message
    success
  }
}`

const GET_ROUND1_EVALUATION = gql`query GetRound1Evaluations($teamId: Int) {
  getRound1Evaluations(teamId: $teamId) {
    user {
      userScore
      teamScore
      id
      eval3
      eval2
      eval1
      User {
        id
        email
        name
        status
        round1_comments
      }
      Team {
        id
        team_name
      }
    }
    success
    errors
  }
}`



export {

  // Rounds
  CREATE_ROUND_QUERY,
  GET_ROUND_QUERY,
  GET_USER_ROUNDS,
  GET_USERS_ROUNDS,
  UPDATE_ROUND_QUERY,
  UPDATE_ROUND_STATUS_QUERY,

  // Question 
  GET_QUESTIONS_QUERY,
  CREATE_QUESTIONS_QUERY,
  UPDATE_QUESTIONS_QUERY,

  // Users
  GET_USERS_QUERY,
  CREATE_USER_QUERY,
  UPDATE_USER_QUERY,
  UPDATE_USER_ISVISITED,
  UPDATE_USER_STATUS,
  GET_INDIVIDUAL_PROFILE,
  ROUND_COMMENTS,

  // Teams
  CREATE_TEAM_QUERY,
  UPDATE_TEAM_QUERY,
  GET_TEAM_QUERY,
  GET_TEAM_MEMBERS,

  // Evaluation
  GET_ROUND1_EVALUATION,
}