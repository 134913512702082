import './assets/i18n/config'
import './App.scss';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link, Navigate, Outlet } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink, useMutation } from '@apollo/client';
import { setContext } from "@apollo/client/link/context"
import LoginPage from './pages/guest/Login/Login.page';
import AdminLayout from './pages/admin/admin.layout';
import AssocationListPage from './pages/admin/visitor-tab-management/assocation';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { getTheme } from './theme/base-theme';
import GuestPage from './pages/guest/Guest-Page/GuestPage';
import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { updateAccessTokenAndLoginStatus } from './store/reducers/AuthSlice';
import { stopAppLoading } from './store/reducers/AppSlice';
import { SnackbarProvider } from 'notistack';
import { Box, CircularProgress, Paper } from '@mui/material';
import { FacebookCircularProgress } from './progress-bar';
import logo from './assets/images/aiotrix.png'
import { LanguageProvider } from './shared/provider/LanguageProvider';
import QrScan from './pages/admin/qrcode/QrScan';
import Users from './pages/admin/users/users';
import Rounds from './pages/admin/rounds/rounds';
import Questions from './pages/admin/questions/questions';
import Teams from './pages/admin/teams/teams';
import EkalavyaDashboard from './pages/ekalavya/dashboard/dashboard';
import Evaluation from './pages/admin/evaluation/evaluation';
import UsersAnsList from './pages/admin/evaluation/round2/UserTableList';



const routes = [
  {
    path: "/",
    element: <GuestPage />,
    children: [
      {
        index: true,
        element: <Navigate to={"/login"} />
      },

      {
        path: "login",
        index: true,
        element: <LoginPage />,
      },



    ]

  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={"/admin/users"} />
      },
   
      {
        path: "users",
        element: <Users />
      },
      {
        path: "rounds",
        element: <Rounds />
      },
      {
        path: "questions",
        element: <Questions />
      },
      {
        path: "teams",
        element: <Teams />
      },
      {
        path: "evaluation",
        element: <Evaluation />
      },
      {
        path: "evaluation/detail",
        element: <UsersAnsList />
      },

    ]
  },
  // {
  //   path: "/ekalavya",
  //   element: <Outlet />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to={"/ekalavya/dashboard"} />
  //     },
  //     {
  //       path: "dashboard",
  //       element: <EkalavyaDashboard />
  //     },

  //   ]
  // },


];

function renderRoutes(routeConfig: any[]) {

  return (
    <>
      {routeConfig.map(({ path, element, children, index }: any) => (
        <Route key={index + "_" + path} path={path && path} element={element} index={index && index}>
          {children && renderRoutes(children)}
        </Route>
      ))}
    </>
  );
}


const REFRESH_TOKEN_QUERY = gql`
mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    errors
    success
    token {
      accessToken
      refreshToken
    }
  }
}

`;

function AppWrapper() {

  const [cookies] = useCookies(['token']);


  const httpLink = createHttpLink({
    uri: 'https://ekl.api.aiotrix.com/',

    // uri: 'http://localhost:6868/'
  });

  const authSetup = setContext((_, { headers }: any) => {

    return {
      headers: {
        ...headers,
        authorization: cookies.token ? `Bearer ${cookies.token}` : ''
      }
    }
  })

  const client = new ApolloClient({
    link: authSetup.concat(httpLink),
    cache: new InMemoryCache(),
  });


  return (
    <ApolloProvider client={client}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ApolloProvider>
  )
}


function App() {
  const dispatch = useDispatch();
  const themeSetting = useSelector((state: any) => state.app.theme);
  const appLoading = useSelector((state: any) => state.app.applicationLoading);
  const pageLoading = useSelector((state: any) => state.app.pageLoading);
  const [cookies, setCookie] = useCookies(['token', 'refreshToken']);
  const [refreshTokenApi, { data, loading, error }] = useMutation(REFRESH_TOKEN_QUERY);
  const timer = useRef<any>(null);



  useEffect(() => {
    checkToken();
  }, [])

  useEffect(() => {

    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (cookies.token) {
      startRefreshTokenTimer();
    }
  }, [cookies.token])

  const decodeToken = (token: string) => {
    const jwtToken: any = jwt_decode(token);
    const expires = jwtToken && new Date(jwtToken.exp * 1000);
    let timeout = expires && expires.getTime() - Date.now() - (60 * 1000);
    timeout = timeout - 50000;
    if (timeout < 0) timeout = 0;
    return timeout;
  }

  const checkToken = async () => {
    try {
      const token = cookies.token;
      const rToken = cookies.refreshToken;
      const timeout = decodeToken(token);
      const rTimeout = decodeToken(rToken);
      if (timeout > 0) {
        var decoded: any = jwt_decode(token);
        dispatch(updateAccessTokenAndLoginStatus({ token: token, isLoggedIn: true, decodedTokenData: decoded, role: decoded?.roles }))
        dispatch(stopAppLoading())
      } else if (rTimeout > 0) {
        const res = await refreshToken();
        dispatch(stopAppLoading())
      }
    } catch (e) {
      dispatch(updateAccessTokenAndLoginStatus({ token: null, isLoggedIn: false }))
      dispatch(stopAppLoading())
    }
  }


  const startRefreshTokenTimer = () => {
    try {
      const token = cookies.token;
      if (!token) return;

      const rToken = cookies.refreshToken;
      const timeout = decodeToken(token);
      const rTimeout = decodeToken(rToken);


      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        if (rTimeout > 0) {
          refreshToken();
        }
      }, timeout);
    } catch (e) {

    }
  }

  const refreshToken = async () => {
    let res = await refreshTokenApi({ variables: { refreshToken: cookies.refreshToken } })

    if (!res.data.refreshToken.success) {
      let err = res.data.refreshToken.errors
      dispatch(updateAccessTokenAndLoginStatus({ token: null, isLoggedIn: false }))
    } else {
      var decoded = jwt_decode(res.data.refreshToken.token.accessToken);
      dispatch(updateAccessTokenAndLoginStatus({ token: res.data.refreshToken.token.accessToken, isLoggedIn: true, decodedTokenData: decoded }))
      setCookie('token', res.data.refreshToken.token.accessToken, { path: '/' })
      setCookie('refreshToken', res.data.refreshToken.token.refreshToken, { path: '/' })
    }
  }



  return (
    <ThemeProvider theme={getTheme(themeSetting)}>
      <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{
        fontSize: '1.5rem'
      }}>
        {
          appLoading ? <>
          </> : (
            <BrowserRouter>
              <Routes>
                {
                  renderRoutes(routes)
                }
              </Routes>
            </BrowserRouter>
          )
        }
      </SnackbarProvider>

      {
        appLoading || pageLoading && (
          <Paper className="application-loading-wrapper">
            <Box sx={{ width: 300 }}>
              <img src={logo} style={{ width: '100%' }} className="application-loading-logo" />
            </Box>
            <FacebookCircularProgress />
          </Paper>
        )
      }
    </ThemeProvider>
  );
}

export default AppWrapper;
