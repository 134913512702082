import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Paper, Slider, FormControlLabel, Typography, Checkbox, TableFooter } from '@mui/material';
import { gql, useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import { eval1, eval2, eval3 } from '../../../utils/Logo/variables';
import ActionMenu from '../../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { getWidget } from '../../../../shared/widgets';
import Form from '../../../../components/Form/Form';
import { ROUND_COMMENTS } from '../../query';
import ConfirmModal from "../../../../shared/hoc/modal/sendEmail";

const create_evaluation = gql`mutation CreateRound1Evalution($evaluations: [DESIGNQUEST_Round1EvalutionInput], $teams: [DESIGNQUEST_Round1EvalutionTeam]) {
    createRound1Evalution(evaluations: $evaluations, teams: $teams) {
      message
      errors
      success
    }
  }`


const delete_team_member = gql`
mutation Mutation($teamId: Int, $userId: Int) {
  deleteTeamMember(teamId: $teamId, userId: $userId) {
    errors
    message
    success
  }
}`

const INITIAL_DATA = {
    comments: '',
};

export default function DataTable({ userData, parentChecked, teamsId, handleRefetch }: any) {
    const [sliderValues, setSliderValues] = useState<{ [key: string]: { slider1: number, slider2: number, slider3: number } }>({});
    const [rowChecked, setRowChecked] = useState<{ [key: string]: boolean }>({});
    const [createRound1Evalution] = useMutation(create_evaluation);
    const [deleteTeamMember] = useMutation(delete_team_member);
    const [cookies] = useCookies();
    const [userDetails, setUserDetails] = useState()
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [formValues, setFormValues] = useState(INITIAL_DATA);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [confirmModalOpen, setconfirmModalOpen] = React.useState(false);
    const [userComments] = useMutation(ROUND_COMMENTS);

    useEffect(() => {
        const initialSliderValues = userData.reduce((acc: any, row: any) => {
            if (row.User) {
                acc[row.User.id] = { slider1: row.eval1 || 0, slider2: row.eval2 || 0, slider3: row.eval3 || 0 };
            } else {
                acc['team'] = { slider1: row.eval1 || 0, slider2: row.eval2 || 0, slider3: row.eval3 || 0 };
            }
            return acc;
        }, {});
        if (!initialSliderValues['team']) {
            initialSliderValues['team'] = { slider1: 0, slider2: 0, slider3: 0 };
        }
        setSliderValues(initialSliderValues);

        setUserDetails(cookies.user)

    }, [userData]);

    useEffect(() => {
        const updatedRowChecked = userData.reduce((acc: any, row: any) => {
            if (row.User) {
                acc[row.User.id] = row.User.status === 'Round 2' || parentChecked;
            }
            return acc;
        }, {});
        setRowChecked(updatedRowChecked);
    }, [parentChecked, userData]);


    const handleSliderChange = (rowId: string, slider: string) => (
        event: Event,
        newValue: number | number[]
    ) => {
        if (typeof newValue === 'number') {
            setSliderValues(prevValues => ({
                ...prevValues,
                [rowId]: {
                    ...prevValues[rowId],
                    [slider]: newValue
                }
            }));
        }
    };

    const handleCheckboxChange = (row: any) => {
        const updatedRowChecked = { ...rowChecked, [row.User.id]: !rowChecked[row.User.id] };
        setRowChecked(updatedRowChecked);
    };

    const handleSubmit = async () => {
        const evals = userData.filter((row: any) => row.User).map((row: any) => ({
            teamId: parseInt(teamsId),
            userId: parseInt(row.User.id),
            eval1: sliderValues[row.User.id].slider1,
            eval2: sliderValues[row.User.id].slider2,
            eval3: sliderValues[row.User.id].slider3,
            userScore: sliderValues[row.User.id].slider1 + sliderValues[row.User.id].slider2 + sliderValues[row.User.id].slider3,
            teamScore: 0,
            userStatus: rowChecked[row.User.id]
        }));

        const team = {
            teamId: parseInt(teamsId),
            userId: null,
            eval1: sliderValues['team'].slider1,
            eval2: sliderValues['team'].slider2,
            eval3: sliderValues['team'].slider3,
            userScore: 0,
            teamScore: sliderValues['team'].slider1 + sliderValues['team'].slider2 + sliderValues['team'].slider3,
            userStatus: 0
        };

        try {
            const { data } = await createRound1Evalution({ variables: { evaluations: evals, teams: [team] } });
            enqueueSnackbar('User scores updated successfully', { variant: "success" });

        } catch (error) {
            console.error('Error while evaluation:', error);
        }
    };

    const FormFields = {
        form_name: "Comments",
        inputControls: [
            { name: "comments", label: "Comments", value: formValues.comments, multiline: true }
        ]
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues({
            ...formValues,
            [name]: value
        });

    }

    const handleReset = () => {
        setFormValues(INITIAL_DATA)
        setAddModalOpen(false)
    }

    const handleModal = () => {
        setconfirmModalOpen(false)
    }

    const handleFormData = (data: any) => {
        setFormValues({ comments: data.comments })
    }


    const handleUserTeam = async () => {

        console.log(selectedUser)
        const variables = {

            userId: parseInt(selectedUser.User.id),
            teamId: parseInt(selectedUser.Team.id)
        };

        try {
            const { data } = await deleteTeamMember({ variables: variables });

            const responseData = data.deleteTeamMember;

            if (responseData.errors?.length > 0) {
                enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(responseData.message[0], { variant: "success" });
                handleReset();
                handleRefetch(parseInt(teamsId))
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }

    }

    const handleCommentSubmit = async () => {

        const variables = {
            comments: formValues.comments,
            userId: parseInt(selectedUser.User.id),
            roundId: 1
        };

        try {
            const { data } = await userComments({ variables: variables });

            const responseData = data.updateUserComments;

            if (responseData.errors?.length > 0) {
                enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(responseData.message[0], { variant: "success" });
                handleReset();
                handleRefetch(parseInt(teamsId))
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }

    }

    return (
        <>
            <TableContainer component={Paper}>
                {userData.length > 0 ? (
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="center">Kiran</TableCell>
                                <TableCell align="center">Karthik</TableCell>
                                <TableCell align="center">Likith</TableCell>
                                <TableCell align="center">Total</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Comments</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userData.map((row: any) => row.User && (
                                <TableRow key={row.User.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{row.User.name}</TableCell>
                                    <TableCell align="left">{row.User.email}</TableCell>

                                    <TableCell align="center">
                                        <Slider
                                            aria-label="Kiran"
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks
                                            min={0}
                                            max={10}
                                            value={sliderValues[row.User.id]?.slider1 || 0}
                                            onChange={handleSliderChange(row.User.id, 'slider1')}
                                            disabled={userDetails === eval1 ? false : true}
                                        />
                                    </TableCell>

                                    <TableCell align="center">
                                        <Slider
                                            aria-label="Karthik"
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks
                                            min={0}
                                            max={10}
                                            value={sliderValues[row.User.id]?.slider2 || 0}
                                            onChange={handleSliderChange(row.User.id, 'slider2')}
                                            disabled={userDetails === eval2 ? false : true}
                                        />
                                    </TableCell>

                                    <TableCell align="center">
                                        <Slider
                                            aria-label="Likith"
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks
                                            min={0}
                                            max={10}
                                            value={sliderValues[row.User.id]?.slider3 || 0}
                                            onChange={handleSliderChange(row.User.id, 'slider3')}
                                            disabled={userDetails === eval3 ? false : true}
                                        />
                                    </TableCell>

                                    <TableCell align="center">
                                        {sliderValues[row.User.id] ? sliderValues[row.User.id].slider1 + sliderValues[row.User.id].slider2 + sliderValues[row.User.id].slider3 : 0}
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControlLabel
                                            control={<Checkbox checked={rowChecked[row.User.id] || false} onChange={() => handleCheckboxChange(row)} />}
                                            label="Round 2"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            {row.User.round1_comments}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <ActionMenu
                                            button={{
                                                type: "icon-button",
                                                icon: <MoreVertOutlined />,
                                                size: 'small',
                                                color: 'primary',
                                                sx: { color: 'white' }

                                            }}
                                            children={
                                                [
                                                    <MenuItem key={row.id} onClick={(event) => { setAddModalOpen(true); event.stopPropagation(); setSelectedUser(row) }}>Comments</MenuItem>,
                                                    <MenuItem key={row.id} onClick={(event) => { setconfirmModalOpen(true); event.stopPropagation(); setSelectedUser(row) }}>Remove</MenuItem>

                                                ]
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ background: '#181818' }}>
                                <TableCell component="th" scope="row"></TableCell>
                                <TableCell align="left">Team Score:</TableCell>

                                <TableCell align="center">
                                    <Slider
                                        aria-label="Team Score 1"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks
                                        min={0}
                                        max={10}
                                        value={sliderValues['team']?.slider1 || 0}
                                        onChange={handleSliderChange('team', 'slider1')}
                                        disabled={userDetails === eval1 ? false : true}
                                    />

                                </TableCell>

                                <TableCell align="center">
                                    <Slider
                                        aria-label="Team Score 2"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks
                                        min={0}
                                        max={10}
                                        value={sliderValues['team']?.slider2 || 0}
                                        onChange={handleSliderChange('team', 'slider2')}
                                        disabled={userDetails === eval2 ? false : true}
                                    />
                                </TableCell>

                                <TableCell align="center">
                                    <Slider
                                        aria-label="Team Score 3"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks
                                        min={0}
                                        max={10}
                                        value={sliderValues['team']?.slider3 || 0}
                                        onChange={handleSliderChange('team', 'slider3')}
                                        disabled={userDetails === eval3 ? false : true}
                                    />
                                </TableCell>

                                <TableCell align="center">
                                    {sliderValues['team'] ? sliderValues['team'].slider1 + sliderValues['team'].slider2 + sliderValues['team'].slider3 : 0}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center" onClick={handleSubmit}><Button>Submit</Button></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                ) : (
                    <Typography style={{ textAlign: 'center' }}>No Data</Typography>
                )}
            </TableContainer>

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Feedback",
                        modalOpen: addModalOpen,
                        onClose: () => setAddModalOpen(false),
                        size: 'sm',
                        children: <Form formFields={FormFields} handleClose={handleReset} handleChange={handleInputChange} handleSubmit={handleCommentSubmit} />,
                    }
                )
            }

            <ConfirmModal modalOpen={confirmModalOpen} onClose={() => setconfirmModalOpen(false)} onConfirm={handleUserTeam} title={'Are you sure?'} children={'Do you want to delete the user from this team?'} />
        </>
    );
}
