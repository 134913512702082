import React, { useMemo } from 'react'
import AdminLayoutHoc from '../../shared/hoc/admin-layout/AdminLayout.default'
import logo from '../../assets/images/aiotrix.png'
import logo1 from "../../assets/images/aiotrix.png";
import { GridViewOutlined, PersonOutline, TaskAltOutlined, QuestionAnswerOutlined, GroupsOutlined, RuleOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';






export default function AdminLayout() {
  const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
  const role = decodedTokenData?.roles ?? ""

  const ADMIN_LAYOUT_CONFIG = useMemo(() => {
    const role = decodedTokenData?.roles ?? ""
    return {
      logo: <div><img src={logo} style={{ 'marginLeft': '-10px' ,'height':'55px','width':'120px'}}></img></div>,
      logoSm: <div><img src={logo1}></img></div>,
      isOpen: false,
      menuLinks: [
        // ...(
        //   (role == "admin")? [
        //     {
        //       text: 'All registered members',
        //       icon: <GridViewOutlined />,
        //       link: "/admin/dashboard"
        //     }
        //   ]: []
        // ),
        ...(
          (role == "admin")? [
            {
              text: 'Users',
              icon: <PersonOutline />,
              link: "/admin/users"
            }
          ]: []
        ),
        ...(
          (role == "admin")? [
            {
              text: 'Rounds',
              icon: <TaskAltOutlined />,
              link: "/admin/rounds"
            }
          ]: []
        ),
        ...(
          (role == "admin")? [
            {
              text: 'Question',
              icon: <QuestionAnswerOutlined />,
              link: "/admin/questions"
            }
          ]: []
        ),
        ...(
          (role == "admin")? [
            {
              text: 'Teams',
              icon: <GroupsOutlined />,
              link: "/admin/teams"
            }
          ]: []
        ),
        ...(
          (role == "admin")? [
            {
              text: 'Evaluation',
              icon: <RuleOutlined />,
              link: "/admin/evaluation"
            }
          ]: []
        ),
      ]
    }
  }, [decodedTokenData])

  return (
    <AdminLayoutHoc {...ADMIN_LAYOUT_CONFIG} />
  )
}
