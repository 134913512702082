import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox } from '@mui/material';
import DataTable from './DataTable';
import { GET_TEAM_MEMBERS, GET_ROUND1_EVALUATION } from '../../query';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../../store/reducers/AppSlice';


const GET_TEAM_QUERY = gql`query GetAllTeams {
    getAllTeams {
      success
      teams {
        id
        team_name
        team_description
        topic
      }
      errors
      message
    }
  }`

export default function ControlledAccordions() {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { loading, error, data } = useQuery(GET_TEAM_QUERY);
    const { refetch: teamMembers } = useQuery(GET_TEAM_MEMBERS, { skip: true });
    const { refetch: round1Evaluations } = useQuery(GET_ROUND1_EVALUATION, { skip: true });
    const { enqueueSnackbar } = useSnackbar();
    const [getTeamMembers, setGetTeamMembers]: any[] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
    const [accordionChecked, setAccordionChecked] = useState<{ [key: string]: boolean }>({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handleTeamMembers = async (team_id: any) => {
        try {

            let evalArray: any[] = []
            let teamArray: any = []
            let finalArray: any = []

            const { data } = await round1Evaluations({ teamId: parseInt(team_id) });

            const responseData = data.getRound1Evaluations;

            if (responseData.errors?.length > 0) {
                // setGetTeamMembers(responseData.user);
                // enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                evalArray = responseData.user
            }


            const secondApiResponse = await teamMembers({ teamId: parseInt(team_id) });

            if (secondApiResponse.data.getTeamMembers.errors?.length > 0) {
                enqueueSnackbar(secondApiResponse.data.getTeamMembers.errors[0], { variant: "error" });
            } else {
                // setGetTeamMembers(secondApiResponse.data.getTeamMembers.data);
                teamArray = secondApiResponse.data.getTeamMembers.data
            }

            const evalMap = new Map(evalArray.map(item => [item.User?.id, item]));

            teamArray.forEach((teamItem: any) => {
                const evalItem = evalMap.get(teamItem.User.id);

                if (evalItem) {
                    finalArray.push(evalItem);
                    evalMap.delete(teamItem.User.id);
                } else {
                    finalArray.push({ ...teamItem, evalItem: null });
                }
            });

            evalArray.forEach(evalItem => {
                if (!evalItem.User) {
                    finalArray.push(evalItem);
                }
            });

            setGetTeamMembers(finalArray)

        } catch (error) {
            console.error('Error:', error);
        }
    };



    const handleCheckboxChange = (team: any, panel: string) => {
        const updatedTeams = selectedTeams.includes(team)
            ? selectedTeams.filter(selectedTeam => selectedTeam !== team)
            : [...selectedTeams, team];
        setSelectedTeams(updatedTeams);

        const isChecked = !accordionChecked[panel];
        setAccordionChecked({ ...accordionChecked, [panel]: isChecked });

        if (expanded === panel) {
            setExpanded(panel);
        } else {
            setExpanded(false);
        }
    };


    const handleChange = (panel: string, team_id: BigInteger) => (event: React.SyntheticEvent, isExpanded: boolean) => {

        setExpanded(isExpanded ? panel : false);
        handleTeamMembers(team_id)

    };

    return (
        <div>
            {data.getAllTeams.teams.map((team: any) => (
                <Accordion
                    key={team.id}
                    style={{ marginBottom: 10, padding: 10 }}
                    expanded={expanded === `panel${team.id}`}
                    onChange={handleChange(`panel${team.id}`, team.id)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel1bh-content`}
                        id={`panel${team.id}bh-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            <Checkbox checked={accordionChecked[`panel${team.id}`] || false} onChange={() => handleCheckboxChange(team, `panel${team.id}`)} />  {team.team_name}
                        </Typography>
                        {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DataTable userData={getTeamMembers} handleRefetch={() => handleTeamMembers(team.id)} parentChecked={accordionChecked[`panel${team.id}`] || false} teamsId={team.id} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
