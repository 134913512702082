import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { getWidget } from '../../../shared/widgets';
import Form from '../../../components/Form/Form';
import { useSnackbar } from 'notistack';
import { CREATE_TEAM_QUERY, UPDATE_TEAM_QUERY, GET_TEAM_QUERY } from '../query';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';

const INITIAL_DATA = {
    team_name: '',
    team_description: '',
    topic: '',
};

export default function Teams({ status }: any) {

    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const childRef = useRef<ChildRef | null>(null);
    const [formValues, setFormValues] = useState(INITIAL_DATA);
    const [createTeams] = useMutation(CREATE_TEAM_QUERY);
    const [updateTeams] = useMutation(UPDATE_TEAM_QUERY);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [editMode, setEditMode]: any = React.useState(false);
    const [selectedTeams, setSelectedTeams]: any[] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();

    type ChildRef = {
        refetchData: (data: any) => void;
    };

    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Teams");
        }
    }, [pageMeta]);

    const handleRefetch = () => {
        childRef.current?.refetchData({
          search: searchText ?? "",
          status: status ? status : null
        });
      };


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    const FormFields = {
        form_name: "Teams",
        inputControls: [
            {
                name: "team_name",
                label: "Name",
                value: formValues.team_name,
                multiline: false
            },
            {
                name: "team_description",
                label: "Description",
                value: formValues.team_description,
                multiline: false
            },
            {
                name: "topic",
                label: "Topic",
                value: formValues.topic,
                multiline: false
            }
        ]
    }


    const ActionBarConfig: WidgetProps[] = [

    

        {
            type: "button",
            label: responsiveString({ xs: "Add", sm: "Add Teams" }),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true);
                setEditMode(false);
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 3
                }
            }
        },

    ]


    const TableConfig = useMemo(() => {
        return [

            {
                label: "Id",
                align: "left",
                accessor: (row: any) => row.id
            },
            {
                label: "Team Name",
                align: "left",
                accessor: (row: any) => row.team_name
            },

            {
                label: "Team Description",
                align: "left",
                accessor: (row: any) => row.team_description
            },

            {
                label: "Topic",
                align: "left",
                accessor: (row: any) => row.topic
            },
            {
                label: "Member Count",
                align: "left",
                accessor: (row: any) => row.memberCount
            },
            {
                label: "Action",
                align: "left",
                width: 130,
                accessor: (row: any) => {
                    return <>
                        <ActionMenu
                            button={{
                                type: "icon-button",
                                icon: <MoreVertOutlined />,
                                size: 'small',
                                color: 'primary',
                                sx: { color: 'white' }

                            }}
                            children={
                                [
                                    <MenuItem key={row.id} onClick={(event) => {
                                        setSelectedTeams(row);
                                        setAddModalOpen(true)
                                        event.stopPropagation();
                                        setEditMode(true)
                                        handleFormData(row)
                                    }}>Edit Team</MenuItem>,
                                ]
                            }
                        />
                    </>
                }
            }
        ]
    }, [])

    const params = {
        search: searchText,
        status: status ? status : null
    }

    const pagination = {
        enable: true,
        page: page,
        onChange: (page: any) => {
            setPage(page)
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues({
            ...formValues,
            [name]: value
        });

    }


    const handleSubmit = async () => {

        const variables = {
            teamName: formValues.team_name,
            teamDescription: formValues.team_description,
            topic: formValues.topic,
            role: 'admin',
            ...(editMode && {
                updateTeamsId: parseInt(selectedTeams.id)
            })
        }

        try {

            const { data } = editMode ? await updateTeams({ variables }) : await createTeams({ variables })

            const responseData = editMode ? data.updateTeams : data.createTeams;

            if (responseData.errors?.length > 0) {
                console.error('Error:', responseData.errors);
                enqueueSnackbar(responseData.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(responseData.message[0], { variant: "success" });
                handleRefetch()
                handleReset();
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }

    }

    const handleFormData = (data: any) => {

        setFormValues({
            team_name: data.team_name,
            team_description: data.team_description,
            topic: data.topic
        })
    }


    const handleReset = () => {
        setFormValues(INITIAL_DATA)
        setAddModalOpen(false)
    }


    return (
        <>
            <TablePageHoc
                actions={ActionBarConfig}
                columns={TableConfig as TableColumn[]}
                query={GET_TEAM_QUERY}
                params={params}
                pagination={pagination}
                sourceAccessor={(data) => data.getAllTeams.teams}
                countAccessor={(data) => data.getAllTeams.teams}
                ref={childRef}
            />

            {
                getWidget(
                    {
                        type: "modal",
                        title: editMode ? "Edit Question" : "Add Question",
                        modalOpen: addModalOpen,
                        onClose: () => setAddModalOpen(false),
                        size: 'md',
                        children: <Form formFields={FormFields} handleClose={handleReset} handleChange={handleInputChange} handleSubmit={handleSubmit} />,
                    }
                )
            }
        </>
    )
}