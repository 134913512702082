import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import ControlledAccordions from './round1/Accordion';
import QuestionEval from './round2/questionEval';
import Round3UsersList from './round 3/userslist';


export default function Evaluation() {

    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Evaluation");
        }
    }, [pageMeta]);

    return (
        <>

            <BasicTabs tabsData={[

                {
                    label: `Evaluation: Round 1`, content: () => (
                        <div>
                            <ControlledAccordions/>
                        </div>
                    )
                },
                {
                    label: `Evaluation: Round 2`, content: () => (
                        <div>
                            <h4><QuestionEval/></h4>
                        </div>
                    )
                },
                {
                    label: `Evaluation: Round 3`, content: () => (
                        <div>
                            <h4><Round3UsersList></Round3UsersList></h4>
                        </div>
                    )
                },
            ]} />

        </>
    )
}
